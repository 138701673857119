import { safeJsonParse, safeJsonStringify } from "@walletconnect/safe-json";
import AsyncStorage from "@react-native-async-storage/async-storage";

function parseEntry(entry: any) {
    return [entry[0], safeJsonParse(entry[1] ?? "")];
}

class AsyncStorageWithContext {
    context: any;
    asyncStorage: any;
    constructor(context: any) {
        this.context = context;
    }

    async getItem(key: any) {
        let item = await AsyncStorage.getItem(`${this.context}_${key}`);
        if (typeof item === "undefined" || item === null) {
            return undefined;
        }
        return safeJsonParse(item);
    }

    async setItem(key: any, value: any) {
        return AsyncStorage.setItem(`${this?.context}_${key}`, safeJsonStringify(value));
    }

    removeItem(key: any) {
        return AsyncStorage.removeItem(`${this?.context}_${key}`);
    }

    // mergeItem(key: any, value: any) {
    //     return AsyncStorage.mergeItem(
    //         `${this?.context}_${key}`,
    //         value,
    //     );
    // }

    async getKeys() {
        return AsyncStorage.getAllKeys();
    }

    async getEntries() {
        const keys = await this.getKeys();
        const entries = await this.asyncStorage.multiGet(keys);
        return entries.map(parseEntry);
    }
}

export default AsyncStorageWithContext;
