import { Component, JSXElementConstructor, ReactComponentElement, useState } from "react"
import axios from "axios";
import { universalProvider, walletConnectModal } from "./transactionHandler";

export const handleURIchainChanged = (uri: any) => {
  console.log("ON CHAIN CHANGE")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }

}

export const handleURImessage = (uri: any) => {
  console.log("ON MESSAGE")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
}

export const handleURIsession_delete = ({ id, topic }: { id: any, topic: any }) => {
  console.log("ON SESSION DELETE")
  console.log('session_delete', id, topic)
}

export const handleURIsession_update = ({ topic, params }: { topic: any, params: any }) => {
  console.log("ON SESSION UPDATED")
  console.log('session_update', topic, params)
}

export const handleURIaccountsChanged = (uri: any) => {
  console.log("ON ACCOUNT CHANGE")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
}

export const handleURIconnect = async (uri: any) => {
  console.log("ON CONNECT")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
  walletConnectModal.closeModal();
  window.location.reload()
}

export const handleURIsession_event = (uri: any) => {
  console.log("ON SESSION EVENT")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }

}

export const handleURIdisplay_uri = (uri: any) => {
  console.log("ON PROVIDER CONNECT")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
  console.log(universalProvider)
  walletConnectModal.openModal({ uri: uri })
}

export const handleURIdisconnect = (uri: any) => {
  console.log("ON DISCONNECT")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
  window.location.reload()
}
