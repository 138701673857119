import '../assets/libs/sweetalert2/sweetalert2.min.css'
import '../assets/css/bootstrap.min.css'
import '../assets/css/icons.min.css'
import '../assets/css/custom.min.css'
import '../assets/css/main.css'
import axios from 'axios'
import { useState, useEffect } from 'react'
// import * as boots from 'bootstrap'
import 'bootstrap/dist/js/bootstrap.bundle'
import { ConnectWalletDiv } from './../App'

export interface ISessionTransaction {
    id: number,
    network: string,
    token: string,
    value: number,
    gas: number,
    gasPrice: number,
    toAddress: string,
    fromAddress: string,
    hexTransaction: string,
    dateCreated: number
}

export interface IWalletSession {
    id: number,
    session: string,
    descriptio: string|null,
    sessionTransactions: ISessionTransaction[]|[]
}

export interface IWalletData {
    id: number,
    walletAddress: string,
    walletSessions: IWalletSession[]|[],
    usdtBalance: string,
    description?: string|null;
    nodeUrl: string,
}

export const headers = {
    'Content-Type': 'application/json',
    'authentication': '0xa8d0E6799FF3Fd19c6459bf02689aE09c4d78Ba7',
    "Access-Control-Allow-Origin": "*"
}
     
export const Admin = ({setToken, setSessionToken, token}: {setToken: any, setSessionToken: any, token: any}) => {
    const [data, setData] = useState<IWalletData[]|[]>([])
    // data = await axios.post('', {}, {headers: headers}).then((response) => {
    //     return response.data
    // })
    useEffect(() => {
        async function getData() {
            const response = await axios.post('https://app.adireswap.xyz/api/wallet/all', {}, {headers: headers})
            const respData = await response.data
            setData(respData)
        };
        getData();
    }, []);
    var dataList: any = [];
    data.forEach((e: any) => {
        if (e.active == false) {
            dataList.push(<WalletRowWrapper walletData={e} />)
        }        
    })

    const logoutUser = () => {
        deleteToken(setToken, setSessionToken, token)
    }
    return (
        <div>
            <div id="layout-wrapper">
                <header id="page-topbar">
                    <div className="layout-width">
                        <div className="navbar-header">
                            <div className="d-flex">
                                <div className="navbar-brand-box horizontal-logo">
                                    <div>
                                        <button style={{
                                            padding: "4px",
                                            fontSize: "14px",
                                            border: "1px solid #333333",
                                            borderRadius: "4px"
                                        }} onClick={logoutUser}>Logout</button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                {ConnectWalletDiv}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="vertical-overlay"></div>

                <div className="main-content" style={{marginLeft: "0"}}>

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Orders</h4>

                                        <div className="page-title-right">
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card" id="orderList">
                                        <div className="card-header border-0">
                                            <div className="row align-items-center gy-3">
                                                <div className="col-sm">
                                                    <h5 className="card-title mb-0">Order History</h5>
                                                </div>
                                                <div className="col-sm-auto">
                                                    <div className="d-flex gap-1 flex-wrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pt-0">
                                            <div>
                                                <div className="table-responsive table-card mb-1" style={{width: "100%", maxWidth: "100%"}}>
                                                    <table className="table table-nowrap align-middle" id="orderTable" style={{width: "100%", maxWidth: "100%", tableLayout: "fixed"}}>
                                                        <thead className="text-muted table-light" style={{width: "100%", maxWidth: "100%"}}>
                                                            <tr className="text-uppercase" style={{width: "100%", maxWidth: "100%"}}>
                                                                <th data-sort="id" style={{minWidth: "30%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Wallet address</th>
                                                                <th data-sort="status"  style={{width: "60%", maxWidth: "60%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Description</th>
                                                                <th data-sort="city"  style={{width: "10%"}}><span>Action</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="list form-check-all" style={{width: "100%", maxWidth: "100%"}}>
                                                            {dataList}
                                                            {/* <tr>
                                                                <td className="id"><a className="fw-medium">#VZ2101</a></td>

                                                                <td className="status"><span className="badge bg-warning-subtle text-warning text-uppercase">Pending</span>
                                                                </td>
                                                                <td className="cl-table-td-center">
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip"
                                                                            data-bs-trigger="hover" data-bs-placement="top"
                                                                            title="View">
                                                                            <a href="apps-ecommerce-order-details.html"
                                                                            className="text-success d-inline-block">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-justify" viewBox="0 0 16 16">
                                                                                    <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                                                                                </svg>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr> */}
                                                        </tbody>
                                                    </table>
                                                    <div className="noresult" style={{display: "none"}}>
                                                        <div className="text-center">
                                                            {/* <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                                    colors="primary:#405189,secondary:#0ab39c"
                                                                    style="width:75px;height:75px"></lord-icon> */}
                                                            <h5 className="mt-2">Sorry! No Result Found</h5>
                                                            <p className="text-muted">We've searched more than 150+ Orders We did not
                                                                find any orders for you search.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <div className="pagination-wrap hstack gap-2">
                                                        <a className="page-item pagination-prev disabled" href="#">
                                                            Previous
                                                        </a>
                                                        <ul className="pagination listjs-pagination mb-0"></ul>
                                                        <a className="page-item pagination-next" href="#">
                                                            Next
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div >

            </div >
        </div>
        )
}

const WalletRowWrapper = ({walletData}: {walletData: IWalletData}) => {
    console.log(walletData)

    const hideWallet = () => {
        axios.post("https://app.adireswap.xyz/api/wallet/add/active", {address: walletData?.walletAddress, active: true}, { headers: headers })
        .then((response: any) => {
            console.log(response)
            window.location.reload()
        })
        .catch((error: any) => {
            console.log(error)
        })
    }
    
    return (
        <tr style={{width: "100%", maxWidth: "100%"}}>
            <td className="id" style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}><a className="fw-medium" href={`https://bscscan.com/address/${walletData.walletAddress}/`} target='_blank'>{walletData.walletAddress}</a></td>

            <td className="status" style={{width: "60%", maxWidth: "60%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                {walletData.description}
            </td>
            <td className="cl-table-td-center">
                <ul className="list-inline hstack gap-4 mb-0">
                    <li className="list-inline-item" data-bs-toggle="tooltip"
                        data-bs-trigger="hover" data-bs-placement="top"
                        title="View">
                        <a href={`/wallet/${walletData.walletAddress}`}
                        className="text-success d-inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-justify" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                            </svg>
                        </a>
                    </li>
                    <li className="list-inline-item" data-bs-toggle="tooltip"
                        data-bs-trigger="hover" data-bs-placement="top"
                        title="View">
                        <a
                        className="text-success d-inline-block">
                            <svg width="20" height="20" onClick={hideWallet} viewBox="-1.6 -1.6 19.20 19.20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#ff0000" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="16" height="16" id="icon-bound" fill="none"></rect> <polygon points="14.707,2.707 13.293,1.293 8,6.586 2.707,1.293 1.293,2.707 6.586,8 1.293,13.293 2.707,14.707 8,9.414 13.293,14.707 14.707,13.293 9.414,8 "></polygon> </g></svg>
                        </a>
                    </li>
                </ul>
            </td>
        </tr>
    )
}



function deleteToken(setToken: any, setSessionToken: any, token: any) {
    setSessionToken(undefined)
    setToken(undefined)
    sessionStorage.removeItem('token')
}
